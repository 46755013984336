<template>
  <div class="mainHero">
    <img src="/images/xendreqText.svg" class="textLogo" data-aos="zoom-in">
    <a @click="scroll">
      <font-awesome-icon icon="fa-solid fa-angle-down" class="bottomArrow"></font-awesome-icon>
    </a>
  </div>
</template>

<script>
export default {
  name: "Header",
  methods: {
    scroll() {
      const data = document.getElementById('socialMedia').getBoundingClientRect();

      window.scroll(0, data.top + window.scrollY);
    }
  }
}
</script>

<style scoped>
.mainHero {
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.textLogo {
  /*height: 20vh;*/
  /*height: 200px;*/
  /*height: 90px;*/
  min-height: 90px;
  max-height: 200px;
  margin: 0 32px;
}

.bottomArrow {
  position: absolute;
  bottom: 40px;
  margin: 0 auto;
  left: 0;
  right: 0;
  font-size: 3rem;
  color: #ce0fc7;

  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0px);
  }

  40% {
    transform: translateY(-35px);
  }

  60% {
    transform: translateY(-40px);
  }
}
</style>
