<template>
  <div class="socialBox" :style="'background: url(\'' + image + '\') no-repeat center; background-size: cover;'" @click="open(link)">
    <font-awesome-icon :icon="'fa-brands fa-' + type" class="iconn"></font-awesome-icon>
    <div class="textC">Przejdź</div>
  </div>
</template>

<script>
export default {
  name: "SocialBox",
  props: {
    type: String,
    image: String,
    link: String
  },
  methods: {
    open(url) { window.open(url) }
  }
}
</script>

<style scoped>

.socialBox {
  width: 400px;
  padding: 5px;
  border-radius: 32px;
  height: 225px;
  position: relative;

  /*transition: box-shadow .5s;*/

  cursor: pointer;

  /*aspect-ratio: 16 / 9;*/
}

.textC {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-size: 2.25rem;
  font-weight: 500;
  text-shadow: 0px 2px 0px rgba(0, 0, 0, 1);

  opacity: 0;

  transition: opacity .5s;
}

.socialBox:hover > .textC {
  opacity: 1;
}

.socialBox > .iconn {
  position: absolute;
  bottom: 10px;
  right: 20px;

  color: #000;
  font-size: 2.5rem;

  transition: color .5s;
  cursor: pointer;
}

.socialBox:hover {
  box-shadow: inset 0 0 0 400px rgba(206,15,199,.2);
}

.socialBox:hover > .iconn {
  color: var(--primary);
}

</style>
