import Vue from 'vue'
import App from './App.vue'

import './assets/main.scss'

Vue.config.productionTip = false

import { library } from '@fortawesome/fontawesome-svg-core'
import { faYoutube, faTwitch, faDiscord, faInstagram, faTiktok, faTwitter, faSoundcloud, faSpotify, faApple, faFacebook } from '@fortawesome/free-brands-svg-icons'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import router from './router'

library.add(faYoutube, faTwitch, faDiscord, faInstagram, faTiktok, faTwitter, faSoundcloud, faSpotify, faApple, faFacebook, faAngleDown);

Vue.component('font-awesome-icon', FontAwesomeIcon)

import 'aos/dist/aos.css';
import AOS from "aos";

new Vue({
  router,
  render: h => h(App),
  mounted() {
    AOS.init({
      startEvent: 'load',
    });
  }
}).$mount('#app')
